<template>
<div>

    <div class="my-6 text-start">
    <h3 class="font-bold">Criar um novo Promoter</h3>
    <span class="text-sm text-gray-500"
      >Preencha as informações abaixo e clique no botão <b>criar</b> para salvar
      as alterações.
    </span>
  </div>

  <div class="card shadow-sm bg-white border p-8">
    <div class="flex lg:flex-row flex-col mt-2">
      <div class="w-full p-2">
        <Input
          v-model="promoter.documento"
          type="cpf"
          label="Documento CPF"
          placeholder="CPF"
        />
      </div>
      <div class="w-full p-2">
        <Input
          v-model="promoter.usuario"
          type="default"
          label="Email"
          placeholder="Email"
        />
      </div>
      <div class="w-full p-2 relative">
        <Input
          v-model="promoter.senha"
          :type="type"
          placeholder="Senha"
          label="Senha"
        />
        <i :class="`gg-${icon} icon`" @click="changeInputType"></i>
      </div>
    </div>
    <div class="flex justify-end mt-5">
    <Button @click="create" color="primary" text="Criar"></Button>
    </div>
  </div>
</div>


</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, ref } from "vue";
import { POST } from "../../services/api";
import { isEmpty, isEmail } from "../../services/validate";
import { useRouter } from 'vue-router';

export default {
  name: "CriarPromoter",
  components: {
    Input,
    Button,
  },
  data()
  {
    return{
      isMobile: false
    }
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },

  setup() {
    const promoter = ref({
     documento: "",
     usuario: "",
     senha: ""
    });

    const icon = ref("eye");
    const type = ref("password");
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();

    const create = async () => {
      loader.open();
    
      const validatePromoter = (isEmpty(promoter.value));
      const emailValidate = isEmail(promoter.value.usuario);




      if ( validatePromoter || !emailValidate  )  {
       
      if (!emailValidate) 
        alert.open("Atenção!", `O email <b>${promoter.value.usuario}</b> não é válido!`, "warning");
      if (validatePromoter) 
      alert.open("Atenção!", validatePromoter, "warning"); 
     
     } else {
        
        try {


          await POST("promoters", promoter.value);
          alert.open(
            "Sucesso!",
            `Promoter criado com sucesso!`,
            "success"
          );
          loader.close();
          router.push("/promoter");
        } catch (e) {
          alert.open("Atenção!", e, "danger");
        }
      }
      loader.close();


    };

    const changeInputType = () => {
      type.value = type.value == 'password' ? 'default' : 'password';
      icon.value = icon.value == 'eye' ? 'eye-alt' : 'eye';
    };

    return {
      promoter,
      create,
      icon,
      type,
      changeInputType,
    };
  },
};
</script>


<style scoped>
  .icon{
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 38%;
  }

  .btn{
    width: 100%;
    align-items: center;
    justify-content: center;
  }
</style>
